<template>
    <div class="all-box">
        <div class="all-main-box-list" v-show="!allLoading">
            <div class="search-box" @click="clickSelectDate">
                    <span class="span-left">选择日期区间</span>
                    <span class="span-right">
                        <span v-show="this.startTime!=='' && this.endTime !== ''">
                            <span class="time-span">{{this.startTime}} ~ {{this.endTime}} </span>
                            <van-icon name="close" @click.stop="clickQingkong"/> 
                        </span>
                        <van-icon name="arrow" v-show="this.startTime === '' && this.endTime === ''"  />
                    </span>
                </div>
                <van-calendar v-model="showDialog" ref="alltime" type="range" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" @close="closeHandker"/>
            <div class="list-box">
                <div class="list-main-box" v-show="!showKong2">
                    <van-list v-model="loadingList" :finished="finished" finished-text="没有更多了" @load="onLoad" >
                        <div class="list-lis" v-for="(item,index) in dataList" :key="index" @click="clickLis(item)">
                            <div class="lis-box1">
                                <span class="span-left">{{item.jiaBanRenName}}提交的加班申请</span>
                                <span class="span-right">{{item.createTime.slice(0,10)}}</span>
                            </div>
                            <div class="lis-box2">开始时间：{{item.startData}}</div>
                            <div class="lis-box2">结束时间：{{item.endDate}}</div>
                            <div class="lis-box2">时长：{{item.jiaBanShiChang}}时</div>
                            <div class="lis-box3">
                                <div class="lis-left">
                                    <img :src="item.touxiang ? item.touxiang : noImg" alt="" class="img-touxiang">
                                    <span>由{{item.jiaBanRenName}}提交</span>
                                </div>
                                <div class="lis-right">
                                    <span :class="['span-status',{'daishenpi':item.checkState === '待审批'},{'tongguo':item.checkState === '通过'},{'butongguo':item.checkState === '不通过'},{'bohui':item.checkState === '驳回'},{'jinxingzhong':item.checkState === '进行中'}]">{{item.checkState}}</span>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </div>
                <div class="list-kong" v-show="showKong2">
                    <img src="../assets/imgs/img-no-data.png" alt="" class="img-nodata">
                    <p>暂无数据 ~ </p>
                </div>
            </div>
        </div>
        <div class="all-loading-box" v-show="allLoading">
                <div class="content" >
                        <div class="point1"></div>
                        <div class="point2"></div>
                        <div class="point3"></div>
                    </div>
        </div>
    </div>
</template>
        
<script>
import Vue from 'vue';
import {Search,Loading,List } from 'vant';
Vue.use(Search);
Vue.use(Loading);
Vue.use(List);
import imgNoTouxiang from "../assets/imgs/img-no-touxiang.png"
import * as api from "../api"
import * as dd from 'dingtalk-jsapi'; // 此方式为整体加载，也可按需进行加载
export default {
   data() {
    return {
        searchVal:'',
        showSearch:false,
        loadingList:false,
        finished:false,
        pageSize:10,
        currentPage:0,
        id:'',
        dataList:[],
        noImg:imgNoTouxiang,

        showKong2:false,



        showDialog:false,

        endTime:'',
        startTime:'',
        minDate:new Date(Number(new Date().getFullYear()) - 2,0,1),
        maxDate:new Date(Number(new Date().getFullYear()) + 2,0,1),

        id:'',
        allLoading:true,

    }
   },
   mounted () {
        
        this.getLoginInfo()
   },
   methods: {
        getLoginInfo(){
            dd.ready(()=> {
                dd.runtime.permission.requestAuthCode({
                    corpId: "dingf3dc018dc6464b6435c2f4657eb6378f",
                    onSuccess: (resOne)=> {
                        api.getLoginApi(resOne.code).then(res2=>{
                            if(res2){
                                this.allLoading = false
                                this.id = res2.data.data.userid
                                this.currentPage = 0
                                this.dataList = []
                                this.onLoad()
                            }else{
                                this.allLoading = true
                            }
                        })
                    },
                    onFail : function(err) {
                        alert(JSON.stringify(err))
                    }
                })
            })
        },
        // getLoginInfo(){
        //     this.allLoading = false
        //     this.id = '00005924'
        //     this.currentPage = 0
        //     this.dataList = []
        //     this.onLoad()
        // },
        
        onLoad(){//加载数据
            this.currentPage = this.currentPage+1
            api.getJiabanListApi(this.id,this.currentPage,this.pageSize,'','',this.startTime,this.endTime,'').then(res=>{
                console.log(res);
                if(res.data.list.length > 0){
                    this.showKong2 = false
                    res.data.list.forEach(item=>{
                        let date = new Date(item.createTime)
                        item.createT = date.getFullYear() + '.' + this.fillZero(date.getMonth()+1) + '.' + this.fillZero(date.getDate())
                        item.startData = item.jiaBanStartDate ? item.jiaBanStartDate.slice(0,16) : ''
                        item.endDate = item.jiaBanEndDate ? item.jiaBanEndDate.slice(0,16) : ''
                        this.dataList.push(item)
                    })
                    console.log(this.dataList);
                    this.loadingList = false;
                    if (this.dataList.length >= Number(res.data.total)) {
                        this.finished = true;
                    }
                }else{
                    this.showKong2 = true
                }
                
            })
        },
        fillZero(str){
            var realNum;
            if(str<10){
                realNum	= '0'+str;
            }else{
                realNum	= str;
            }
            return realNum;
        },
        clickLis(item){
            console.log(item);
            this.$router.push({name:"workOverState",query:{id:item.businessId}})
        },



        clickSelectDate(){
            this.showDialog = true
        },
        onConfirm(i){
            console.log(i);
            if(i && i !==null){
                let nowDate1 = new Date(i[0]);
                let nowYear1 = nowDate1.getFullYear();
                let nowMonth1 = nowDate1.getMonth()+1;
                let nowToday1 = nowDate1.getDate();
                this.startTime = nowYear1+'-'+this.fillZero(nowMonth1)+'-'+this.fillZero(nowToday1)
                console.log(this.startTime);
                let nowDate2 = new Date(i[1]);
                let nowYear2 = nowDate2.getFullYear();
                let nowMonth2 = nowDate2.getMonth()+1;
                let nowToday2 = nowDate2.getDate();
                this.endTime = nowYear2+'-'+this.fillZero(nowMonth2)+'-'+this.fillZero(nowToday2)
                console.log(this.endTime);
                this.currentPage = 0
                this.dataList = []
                this.onLoad()
            }else{
                this.startTime = ''
                this.endTime = ''
                this.currentPage = 0
                this.dataList = []
                this.onLoad()
            }

            this.showDialog = false
        },  
        clickQingkong(){
            console.log("222");
            this.startTime = ''
            this.endTime = ''
             this.$refs.alltime.reset()
             this.currentPage = 0
             this.dataList = []
                this.onLoad()
        },
        closeHandker(){
            console.log("aaa");
        }
   },

}
</script>
<style lang='less' scoped>
.all-box{
    width: 100%;
    height: 100%;
    // background-color: rgb(244,244,244);
    background-color: #f4f6fc;
    position: relative;
    .all-main-box-list{
        width: 100%;
        height: 100%;
        // background-color: rgb(244,244,244);
        background-color: #f4f6fc;
        position: relative;
    }
    .all-loading-box{
            width: 100%;
            height: 100%;
            background-color: #fff;
            position: relative;
            .content{
                width: 150px;
                height: 100px;
                text-align: center;
                line-height: 100px;
                position: absolute;
                top: 0px;
                right: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                div {
                    width: 10px;
                    height: 10px;
                    background-color: blue;
                    border-radius: 100%;
                    display: inline-block;
                    animation: action 1.5s infinite ease-in-out;
                    animation-fill-mode: both;
                    margin-right: 12px;
                }
                .point1 {
                    animation-delay: -0.3s;
                }
                .point2 {
                    animation-delay: -0.1s;
                }
            }
            @keyframes action {
                0%, 80%, 100% {
                    transform: scale(0);
                }
                40% {
                    transform: scale(1.0);
                }
            }
        }
    .search-box{
        width: 100%;
        height: 46px;
        background-color: #fff;
        padding: 0px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .span-left{
            font-size: 14px;
            letter-spacing: 1px;
        }
        .span-right{
            font-size: 14px;
            letter-spacing: 0.2px;
            .time-span{
                margin-right: 10px;
            }
        }
        // margin-bottom: 7px;
        /deep/ .van-search{
            width: 100%;
            padding: 0px;
            background-color: rgb(243,243,243);
            .van-search__content{
                background-color: rgb(243,243,243);
                border-radius: 8px;
                .van-cell{
                    background-color: rgb(243,243,243);
                }
            }
            
        }
    }
    .list-box{
        width: 100%;
        height: calc(100% - 46px);
        padding:10px;
        .list-main-box{
            width: 100%;
            height: 100%;
            overflow: auto;
        }
        .list-kong{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
                width: 200px;
                height: 150px;
            }
            p{
                font-size: 15px;
                color: #000;
                letter-spacing: 1.4px;
            }
        }
        .list-main-box::-webkit-scrollbar{display:none}
        .list-lis{
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            margin-bottom: 7.5px;
            padding: 15px 14px;
            padding-bottom: 8px;
            .lis-box1{
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                margin-bottom: 9px;
                .span-left{
                    font-size: 15px;
                    letter-spacing: 1.5px;
                    color: #000;
                }
                .span-right{
                    color: rgb(171,171,171);
                    font-size: 13px;
                    letter-spacing: 0.5px;
                    padding-top: 2px;
                    text-align: right;
                }
                
            }
            .lis-box2{
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                color: rgb(171,171,171);
                font-size: 14px;
                letter-spacing: 0.5px;
                margin-top: 6.5px;
                margin-bottom: 6.5px;
            }
            .lis-box3{
                width: 100%;
                margin-top: 9px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .lis-left{
                    display: flex;
                    align-items: center;
                    .img-touxiang{
                        width: 28px;
                        height: 28px;
                        border-radius: 5px;
                    }
                    span{
                        font-size: 14px;
                        letter-spacing: 1px;
                        color: #000;
                        margin-left: 10px;
                    }
                }
                .lis-right{
                    .span-status{
                        font-size: 14px;
                        letter-spacing: 0.5px;
                    }
                    .daishenpi{
                        color: #4e6ef2;
                    }
                    .tongguo{
                        color: rgb(41, 189, 100);
                    }
                    .butongguo{
                        color: red;
                    }
                    .bohui{
                        color: red;
                    }
                    .jinxingzhong{
                        // color: #6272D6;
                        color: rgb(79,20,127);
                    }
                }
                
            }
        }
        .list-lis:last-child{
            margin-bottom: 0px;
        }
    }
}
</style>